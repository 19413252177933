import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SiteText from '../components/sitetext';

const Impressum = () => {
  const site = useStaticQuery(graphql`
    query impressumDataQuery
    {
      wordpressPage(wordpress_id: { eq: 37 }) {
        title
        content
      }
    }
    `);
  return (
    <Layout title="Impressum">
      <SiteText>
        <h1>{site.wordpressPage.title}</h1>
        <div id="mainText" dangerouslySetInnerHTML={{ __html: site.wordpressPage.content }} />
      </SiteText>
    </Layout>
  );
};
export default Impressum;
